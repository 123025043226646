@import url('https://fonts.googleapis.com/css2?family=DM+Serif+Display&family=Open+Sans:wght@300&family=Sen:wght@700&display=swap');@import url('https://fonts.googleapis.com/css?family=Montserrat:400|Inconsolata:400');
@import url('https://fonts.googleapis.com/css2?family=Sen:wght@700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300&family=Sen:wght@700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Abril+Fatface&family=DM+Serif+Display&family=Open+Sans:wght@300&family=Sen:wght@700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Abril+Fatface&family=DM+Serif+Display&family=Open+Sans:wght@300&family=Sen:wght@700&family=Signika:wght@300&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Abril+Fatface&family=DM+Serif+Display&family=Open+Sans:wght@300&family=Oxygen:wght@700&family=Sen:wght@700&family=Signika:wght@300&display=swap');
*,
::after,
::before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
:root {
  /* COLORS  */
  /* primary */
  --primary-0: #f0efff;
  --primary-50: #d8d6ff;
  --primary-100: #c1beff;
  --primary-200: #aaa5ff;
  --primary-300: #938dff;
  --primary-400: #7b74ff;
  /* main */
  --primary-500: #645cff;
  --primary-600: #554ed9;
  --primary-700: #4640b3;
  --primary-800: #37338c;
  --primary-900: #282566;
  --primary-1000: #191740;
  --primary-1100: #0a0919;
  /* grey */
  --grey-0: #fafbfc;
  --grey-50: #f2f4f8;
  --grey-100: #eaedf3;
  --grey-200: #e2e7ef;
  --grey-300: #dbe0ea;
  --grey-400: #d3dae6;
  --grey-500: #cbd3e1;
  --grey-600: #adb3bf;
  --grey-700: #8e949e;
  --grey-800: #70747c;
  --grey-900: #51545a;
  --grey-1000: #333538;
  --grey-1100: #141516;

  /* rest */
  --black: #222;
  --white: #fff;
  --red-light: #f8d7da;
  --red-dark: #842029;
  --green-light: #d1e7dd;
  --green-dark: #0f5132;
  /* box shadows */
  --shadow-1: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  --shadow-2: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
    0 2px 4px -1px rgba(0, 0, 0, 0.06);
  --shadow-3: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
    0 4px 6px -2px rgba(0, 0, 0, 0.05);
  --shadow-4: 0 20px 25px -5px rgba(0, 0, 0, 0.1),
    0 10px 10px -5px rgba(0, 0, 0, 0.04);
  /* typography */
  /* rest */
  --backgroundColor: var(--grey-50);
  --textColor: var(--grey-1000);
  --letterSpacing: 1px;
  --transition: 0.3s ease-in-out all;
  --max-width: 1120px;
}
html {
  scroll-behavior: smooth;
  overflow: hidden;
  min-height: 100%;
  height: 100%;
}


body {

  font-family: var(--bodyFont);
  line-height: 1.75;
  height: 100%;
}

.bodyOutput {
  background: #006494;
  min-height: 100%;
  position: fixed;
  min-width: 100%;
  overflow: auto;
}
.bodyTitle {
  color: #e8f1f2;
  font-family: 'DM Serif Display', serif;
  font-size: 140px;
  text-align: center;
  position: absolute;
  top: 50%;
  overflow: hidden;
  left: 50%;
  display: inline-block;
  transform: translate(-50%, -80%);
  white-space: nowrap;
}
.bodySubtitle {
  color: #13293d;
  font-family: 'DM Serif Display', serif;
  font-size: 50px;
  position: absolute;
  text-align: center;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -40%);
  text-transform: lowercase;
  white-space: nowrap;
}
.imgg{
  height: 60px;
  padding-top: 7px;
}
.aboutOutput {
  background: #006494;
  min-height: 100%;
  position: fixed;
  min-width: 100%;
}
.row {
  background: #006494;
  min-height: 100%;
  position: relative;
  min-width: 100%;
  display: inline-block;

}
.imgggg {
  height: 60px;
  margin-bottom: 60px;
  margin-top: 80px;
  cursor: pointer;
  margin-left: 10%;
}
.linkedin{
  height: 100%;
  width: 30px;
  margin-right: 10px;
}
.roww {
  background: #006494;
  min-height: 100%;
  position: fixed;
  margin-top: 40px;
  min-width: 100%;
  display: inline-block;

}
.experienceOutput {
  background: #006494;
  min-height: 100%;
  position: fixed;
  min-width: 100%;
}
hr{
  border-top: 3px solid #247ba0;
  border-bottom: 0px;
  background-color: #247ba0;
  border-right: 0px;
}
.test{
  border-top: 3px solid #247ba0;
  border-bottom: 0px;
  margin-top: 13px;
  margin-bottom: 0px;
}
.testt{
  border-top: 3px solid #247ba0;
  border-bottom: 0px;
  margin-top: 6px;
  margin-bottom: 0px;  
}
.headshot{
  height: 400px;
}
.uscLogo{
  height: 130px;
  margin-top: 25px;
}
.GSLogo{
  height: 105px;
  margin-top: 0;
  margin-bottom: 17px;
}
.CAISLogo{
  height: 130px;
  margin-top: 17px;
}
.ScopeLogo{
  height: 57px;
  margin-top: 30px;
}
.ViterbiLogo{
  height: 65px;
  margin-bottom: 15px;
}
.experienceHeader {
  white-space: nowrap;
  font-family: 'Abril Fatface', cursive;
  color: #13293d;
  font-weight: bold;
  text-align: center;
  margin-bottom: 0px;
  margin-top: 0px;
}
.bioHeader {
  white-space: nowrap;
  font-family: 'Abril Fatface', cursive;
  color: #13293d;
  font-weight: bold;
  text-align: center;
}
.bioHeaderTwo {
  white-space: nowrap;
  font-family: 'Abril Fatface', cursive;
  color: #13293d;
  font-weight: bolder;
  font-style: italic;
  text-align: center;
}
.bioText {
  font-family: 'Signika', sans-serif;
  color: #1b98e0;
  font-size: 15px;
  max-width: 625px;
}
.experienceText {
  font-family: 'Signika', sans-serif;
  color: #1b98e0;
  font-size: 15px;
}
.bioTextTwo {
  font-family: 'Signika', sans-serif;
  color: #e8f1f2;
  font-style: italic;
  font-weight: bold;
  font-size: 15px;
}
.bioTextThree {
  font-family: 'Signika', sans-serif;
  color: #990000;
  font-style: italic;
  font-weight: bold;
  font-size: 15px;
}
.divOne{
margin-left: auto;
float: left;
padding-top: 10%;
padding-left: 10%;
}
.divOneE{
  float: left;
  padding-top: 1%;
  padding-left: 16%;
  }
  .divOneExpLeft{
    margin-left: auto;
    padding-top: 1%;
    display: inline-block;
    padding-left: 1%;
    }
    .divOneExpLeftToo{
      margin-left: auto;
      display: inline-block;
      padding-left: 1%;
      padding-right: 0%;
      }
    .divOneExpRight{
      display: inline-block;
      margin-right: auto;
      float: right;
      }
      .divOneExpRightToo{
        display: inline-block;
        margin-right: auto;
        float: right;
        padding-top: 1%;
        padding-right: 1.4%;
        }
.divTwo{
  float: right;
  margin-right: auto;
  max-width: 650px;
  padding-top: 9.5%;
  padding-right: 15%;
}
.divTwoE{
  padding-top: 1%;
  padding-left: 29%;
  max-width: 1065px;
}
.divTwoExpLeft{
  padding-top: 1%;
  display: inline-block;
  padding-left: 2%;
  max-width: 50%;
}
.divTwoExpLeftToo{
  padding-top: 1%;
  padding-left: 2%;
  display: inline-block;
  max-width: 50%;
}
.divTwoExpRight{
  display: inline-block;
  padding-top: 1%;
  max-width: 50%;
  float: right;
  padding-right: 3%;
}
.divTwoExpRightToo{
  display: inline-block;
  padding-top: 1.1%;
  max-width: 50%;
  float: right;
  padding-right: 6%;
}
.experienceTitle{
  font-family: 'Oxygen', sans-serif;
  color: #e8f1f2;
  font-weight: bolder;
  font-size: 19px;
  margin-bottom: 2px;
  white-space: nowrap;
  
}
.experienceSubtitle{
  font-family: 'Signika', sans-serif;
  color: #1b98e0;
  font-size: 15px;
  margin-bottom: 0px;
}
.experienceSubtitleWhite{
  font-family: 'Signika', sans-serif;
  color: #e8f1f2;
  font-size: 15px;
  margin-bottom: 0px;
}
.experienceSubtitleBold{
  font-family: 'Signika', sans-serif;
  font-weight: bold;
  color: #13293d;
  font-size: 15px;
  font-style: italic;
  margin-bottom: 0px;
}
.navbar {
  background: #006494;
  height: 64px;
  display: flex;
  margin-right: 5%;
  align-items: center;
  justify-content: center;
  font-family: 'Open-Sans', sans-serif;
}
.foot {
  width: 100%;
  position: absolute;
  bottom: 60px;
  height: 50px;
  display: flex;
  justify-content: center;
}
.title {
  display: block;
  overflow: show;
  background: #006494;

}
.header-name {
  font-family: 'Sen', sans-serif;
  font-size: 30px;
  color: #e8f1f2;
}

.nav-center {
  width: 100%;
  max-width: var(--max-width);
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.nav-center a {
  transition: var(--transition);
}
.nav-center a:not(:last-of-type) {
  margin-right: 0.25rem;
}
.sticky {
  position: sticky;
  top: 0;
}
.home,
.about,
.projects,
.contact,
.rest {
  min-height: 60vh;
  text-align: center;
}

.home {
  background: #bee5bf;
}
.about {
  background: #dff3e3;
}
.projects {
  background: #ffd1ba;
}
.contact {
  background: #ce7da5;
}


p {
  margin-bottom: 1rem;
}

h1,
h2,
h3,
h4,
h5 {
  margin: 0;
  margin-bottom: 1.38rem;
  font-family: var(--headingFont);
  font-weight: 400;
  line-height: 1.3;
  letter-spacing: var(--letterSpacing);
}

h1 {
  font-size: 3.052rem;
}

h2 {
  font-size: 2.441rem;
}

h3 {
  font-size: 1.953rem;
}

h4 {
  font-size: 1.563rem;
}

h5 {
  font-size: 1.25rem;
}

small,
.text_small {
  font-size: var(--smallText);
}

ul {
  padding: 0;
  list-style-type: none;
}
.inactive {
  text-decoration: none;
  font-family: 'Sen', sans-serif;
  font-size: 19px;
  color: #1b98e0;
  font-weight: bold;
  padding-bottom: 3px;
}

.active {
  text-decoration: none;
  font-family: 'Sen', sans-serif;
  font-size: 19px;
  color: #13293d;
  font-weight: bolder;
  border-bottom: 5px solid black;
  padding-bottom: 3px;
}
a:hover {
  color: #13293d;
  font-weight: bolder;
  border-bottom: 3px solid black;
  padding-bottom: 3px;
}
a + a {
  margin-left: 10px;
}

.linkedinn:visited, .linkedinn:hover, .linkedinn:link{
  color: #13293d;
}
.linkedinn{
  margin-left: 0px;
}
@media only screen and (max-width: 460px) {
  .bodyTitle {
    color: #e8f1f2;
    font-family: 'DM Serif Display', serif;
    font-size: 80px;
    text-align: center;
    position: absolute;
    top: 50%;
    overflow: hidden;
    left: 50%;
    display: inline-block;
    transform: translate(-50%, -80%);
    white-space: nowrap;
  }
  .bodySubtitle {
    color: #13293d;
    font-family: 'DM Serif Display', serif;
    font-size: 30px;
    position: absolute;
    text-align: center;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -40%);
    text-transform: lowercase;
    white-space: nowrap;
  }
  .header-name {
    visibility: hidden;
  }
}
@media only screen and (max-width: 768px) {
  .bodyTitle {
    color: #e8f1f2;
    font-family: 'DM Serif Display', serif;
    font-size: 80px;
    text-align: center;
    position: absolute;
    top: 50%;
    overflow: hidden;
    left: 50%;
    display: inline-block;
    transform: translate(-50%, -80%);
    white-space: nowrap;
  }
  .imgggg {
    margin-left: 5%;
  }
  .bodySubtitle {
    color: #13293d;
    font-family: 'DM Serif Display', serif;
    font-size: 30px;
    position: absolute;
    text-align: center;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -40%);
    text-transform: lowercase;
    white-space: nowrap;
  }
  .divOne{
    margin-left: auto;
    float: left;
    padding-top: 10%;
    margin-right: auto;
    padding-left: 8%;
    }
    .bioHeader {
      margin-left: auto;
      margin-right: auto;
      text-align: center;
      /* padding-left: 15%; */
    }
    .bioHeaderThree {
      visibility: hidden;
    }
    .divTwo {
      padding-left: 40%;
      margin-top: 0%;
      padding-top: 0%;
      margin-left: auto;
      margin-right: auto;
      text-align: center;
    }
    .divTwoE {
      padding-left: 5%;
      /* width: 100%; */
      max-width: 93%;
    }
    .experienceTitle {
      font-size: 17px;
    }
    
}